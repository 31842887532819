<template>
  <div>
    <v-container fill-height fluid grid-list-xl>
      <v-layout
        row
        wrap xs12>
        <v-flex xs12 sm6 md3>
          <v-text-field 
                              label="UUID/PublicKey клиента" 
                              v-model="clientId" 
                              class="shrink"
                              type="text"></v-text-field>
        </v-flex>
        <v-flex xs12 sm8 md4>
          <v-btn color="info" @click="searchbyId">Поиск по UUID</v-btn>
          <v-btn color="info" @click="searchbyPublicKey">Поиск по PublicKey</v-btn>
          <v-btn color="info" @click="clean">Очистить</v-btn>
        </v-flex>
      </v-layout>
    </v-container>

    <v-container
      fill-height
      fluid
      grid-list-xl
    >
      <v-layout
        justify-center
        wrap
      >
        <v-flex
          md12
        >
          <v-flex
            xs12 sm12 md12 lg8
          >
          <material-card
            color="green"
            title="Клиент"
            
          >
            <v-list dense v-if="user">
              <v-list-tile>
                <v-list-tile-content>Id:</v-list-tile-content>
                <v-list-tile-content class="align-end">{{ user.id }}</v-list-tile-content>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-content>Name:</v-list-tile-content>
                <v-list-tile-content class="align-end">{{ user.name }}</v-list-tile-content>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-content>Blockchain Key:</v-list-tile-content>
                <v-list-tile-content class="align-end">{{ user.blockchainKey }}</v-list-tile-content>
              </v-list-tile>
              <v-list-tile>
                <v-list-tile-content>Added:</v-list-tile-content>
                <v-list-tile-content class="align-end">{{ user.added | date }} {{ user.added | time }}</v-list-tile-content>
              </v-list-tile>
            </v-list>
          </material-card>
          </v-flex>
          <material-card
            title="Правила"
            color="green"
            text=""
          >
            <v-data-table
              :headers="headersRules"
              :items="benefitRules"
              hide-actions
            >
              <template
                slot="headerCell"
                slot-scope="{ header }"
              >
                <span
                  class="subheading font-weight-light text-success text--darken-3"
                  v-text="header.text"
                />
              </template>
              <template
                slot="items"
                slot-scope="{ item }"
              >
                <td>{{ item.type }}</td>
                <td>{{ item.stepValue }}</td>
                <td>{{ item.value }}</td>
                <td>{{ item.utcOffset }}</td>
                <td>{{ item.createdOn | date }} {{ item.createdOn | time }}</td>
                <td>{{ item.modifiedOn | date }} {{ item.modifiedOn | time }}</td>
              </template>
            </v-data-table>
          </material-card>
          <material-card
            title="Аккаунты"
            color="green"
            text=""
          >
            <v-data-table
              :headers="headersAccounts"
              :items="accounts"
              hide-actions
            >
              <template
                slot="headerCell"
                slot-scope="{ header }"
              >
                <span
                  class="subheading font-weight-light text-success text--darken-3"
                  v-text="header.text"
                />
              </template>
              <template
                slot="items"
                slot-scope="{ item }"
              >
                <td>{{ item.type }}</td>
                <td>{{ item.balance }}</td>
                <td>{{ item.createdOn | date }} {{ item.createdOn | time }}</td>
                <td>{{ item.modifiedOn | date }} {{ item.modifiedOn | time }}</td>
              </template>
            </v-data-table>
          </material-card>
          <material-card
            color="green"
            title="Товары"
            text="Статистика по товарам"
          >
            <v-data-table
              :headers="orderHeaders"
              :items="orders"
              hide-actions
            >
              <template
                slot="headerCell"
                slot-scope="{ header }"
              >
                <span
                  class="subheading font-weight-light text-success text--darken-3"
                  v-text="header.text"
                />
              </template>
              <template
                slot="items"
                slot-scope="{ item }"
              >
                <td>{{ item.createdOn | date }} {{ item.createdOn | time }}</td>
                <td>{{ getDeviceName(item.deviceId) }}</td>
                <td>{{ item.data.cardId }}</td>
                <td>{{ getShopItemName(item.data.sku) }}</td>
                <td>{{ item.data.itemCount }}</td>
                <td>{{ item.data.portionAmount / 100 }}</td>
                <td>{{ item.data.deductionAmount / 100 }}</td>
                <td>{{ item.data.subsidyAmount / 100 }}</td>
                <td>{{ item.data.moneyAmount / 100 }}</td>
                <td>{{ item.data.amount / 100 }}</td>
              </template>
            </v-data-table>
          </material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    loading: false,
    clientId: null,
    
    headers: [
      {
        sortable: false,
        text: ''
      },
      {
        sortable: true,
        text: 'Id',
        value: 'id'
      },
      {
        sortable: true,
        text: 'Nickname',
        value: 'nickname'
      },
      {
        sortable: true,
        text: 'Blockchain Id',
        value: 'blockchainToken'
      },
      {
        sortable: true,
        text: 'Регистрация',
        value: 'registered'
      }
    ],
    headersRules: [
      {
        sortable: true,
        text: 'Тип',
        value: 'type'
      },
      {
        sortable: true,
        text: 'Период',
        value: 'stepValue'
      },
      {
        sortable: true,
        text: 'Значение',
        value: 'value'
      },
      {
        sortable: true,
        text: 'Смещение',
        value: 'utcOffset'
      },
      {
        sortable: true,
        text: 'Создано',
        value: 'createdOn'
      },
      {
        sortable: true,
        text: 'Изменено',
        value: 'modifiedOn'
      },
    ],
    headersAccounts: [
      {
        sortable: true,
        text: 'Тип',
        value: 'type'
      },
      {
        sortable: true,
        text: 'Баланс',
        value: 'balance'
      },
      {
        sortable: true,
        text: 'Создано',
        value: 'createdOn'
      },
      {
        sortable: true,
        text: 'Изменено',
        value: 'modifiedOn'
      },
    ],
    orderHeaders: [
      {
        sortable: true,
        text: 'Дата',
        value: 'createdOn'
      },
      {
        text: 'Торговый автомат',
        value: 'deviceId'
      },
      {
        text: 'Карта',
        value: 'data.cardId'
      },
      {
        sortable: true,
        text: 'Товар',
        value: 'data.sku'
      },
      {
        sortable: true,
        text: 'Количество',
        value: 'data.itemCount'
      },
      {
        sortable: true,
        text: 'Порция, ₽',
        value: 'data.portionAmount'
      },
      {
        sortable: true,
        text: 'Вычет, ₽',
        value: 'data.deductionAmount'
      },
      {
        sortable: true,
        text: 'Дотация, ₽',
        value: 'data.subsidyAmount'
      },
      {
        sortable: true,
        text: 'Деньги, ₽',
        value: 'data.moneyAmount'
      },
      {
        sortable: true,
        text: 'Итого, ₽',
        value: 'data.amount'
      },
    ],
    user: null,
    benefitRules: [ ],
    accounts: [],
    items: [ ],
    orders: [ ],
    machines: [ ],
    shopItems: [ ]
  }),
  mounted () {
    this.getShopItems()
    this.getVendingMachines()
  },
  methods: {
    resetBeforeSearch () {
      this.user = null
      this.benefitRules = []
      this.accounts = []
    },
    searchbyId () {
      this.resetBeforeSearch()
      this.getUserData(this.clientId)
    },
    searchbyPublicKey () {
      this.getUserByBlockchainKey(this.clientId)
    },
    clean () {
      this.clientId = null
      this.resetBeforeSearch()
    },
    getUserData (userId) {
      this.getUser(userId)
      this.getBenefitRules(userId)
      this.getAccounts(userId)
      this.getOrders(userId)
    },
    getUser (id) {
      this.$http.get('/vendingprofiles/' + id)
        .then(response => {
          this.user = response.data
        })
    },
    getBenefitRules (id) {
      this.$http.get('/vendingprofiles/' + id + '/benefit-rules')
        .then(response => {
          this.benefitRules = response.data
        })
    },
    getAccounts (id) {
      this.$http.get('/vendingprofiles/' + id + '/accounts')
        .then(response => {
          this.accounts = response.data
        })
    },
    getOrders (id) {
      this.$http.get('/vendingprofiles/' + id + '/orders')
        .then(response => {
          this.orders = response.data
        })
    },
    getUserByBlockchainKey (key) {
      var options = {
        params: {
          provider: 'blockchain',
          referenceId: key
        }
      };
      this.$http.get('/vendingprofiles/filters', options)
        .then(response => {
          const userId = response.data.id
          this.getUserData(userId)
        })
    },
    getVendingMachines () {
      this.$http.get('/vendingmachines/')
        .then(response => {
          this.machines = response.data
        })
    },
    getShopItems () {
      this.$http.get('/shopItems')
        .then(response => {
          this.shopItems = response.data
        })
    },
    getDeviceName (deviceId) {
      if (deviceId && this.machines) {
        return this.machines.find(x => x.id === deviceId).name
      }
      return deviceId
    },
    getShopItemName (shopItemId) {
      if (shopItemId && this.shopItems) {
        const shopItem = this.shopItems.find(x => x.id === shopItemId)
        if (shopItem) {
          return shopItem.name
        }
        return shopItemId
      }
      return shopItemId
    }
  }
}
</script>
